.social-name-container {
    border-radius: 100px;
    padding-bottom: 3px !important;
    width: 0 !important;
    opacity: 0;
    overflow: hidden;
    float: right;
    transition: .3s ease-in-out;
}

.social-container:nth-child(1) > .social-name-container {
    background: rgb(148,5,228);
    background: linear-gradient(90deg, rgba(148,5,228,1) 0%, rgba(237,155,38,1) 100%);
}

.social-container:nth-child(2) > .social-name-container {
    background: rgb(38,185,255);
    background: linear-gradient(90deg, rgba(38,185,255,1) 0%, rgba(0,127,188,1) 76%);
}

.social-container:nth-child(3) > .social-name-container {
    background: rgb(64,64,64);
    background: linear-gradient(90deg, rgba(64,64,64,1) 0%, rgba(2,2,2,1) 100%);
}

.social-container:nth-child(4) > .social-name-container {
    background: rgb(238,65,50);
    background: linear-gradient(90deg, rgba(238,65,50,1) 0%, rgba(255,186,0,1) 84%, rgba(255,210,89,1) 90%, rgba(255,255,255,1) 100%);
}

.social-container:hover .social-name-container {
    width: 100% !important;
    opacity: 1;
}

.social-icon {
    width: 90%;
    height: auto;
    transition: .2s ease-in-out;
}
.social-container:hover .social-icon {
    width: 100%;
    transform: translateY(-3px);
}

.logo-text {
    font-size: calc(20px + 4vw);
    text-shadow: rgba(0, 0, 0, 0.25) 0px 1px 5px;
}

.links-title-text {
    color: #444444;
}

.footer-link-button::before {
    content: ' ';
    background-color: var(--dark);
    display: block;
    height: 1px;
    width: 0;
    position: absolute;
    bottom: 0;
    left: 0;
    transition: .2s ease-in-out;
}

.footer-link-button:hover::before {
    width: 100% !important;
}

.footer-lower-section {
    background-color: #30B5DF;
}

.footer-hide-section-small-screen {
    position: absolute;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
}

@media screen and (max-width: 768px) {
    .social-name-container > a {
        position: absolute;
        opacity: 0;
        visibility: hidden;
    }

    .footer-hide-section-big-screen {
        position: absolute;
        opacity: 0;
        visibility: hidden;
        overflow: hidden;
    }

    .footer-hide-section-small-screen {
        position: relative;
        opacity: 1;
        visibility: visible;
        overflow: visible;
    }

    .links-title-text {
        color: #444444;
        font-size: calc(20px + 4vw);
    }

    .footer-link-button {
        font-size: calc(15px + 2vw) !important;
    }

    .footer-link-button::before {
        width: 100%;
        transition: .2s ease-in-out;
    }
}