.about-us-hero-section-cyan-block {
  background: linear-gradient(#6FD1F0 60%, #ffffff 98%);
  /* box-shadow: inset 0px 0px 15px 0px rgba(0, 0, 0, 0.5);
  border-radius: 0.7rem; */
}

.about-us-footer-section-gradient {
  height: 30vh;
  background: linear-gradient(#ffffff 20%, #6FD1F0);
}

.about-us-page-hero-section-our-team-image {
    box-shadow:
  0px 0.5px 0.8px rgba(0, 0, 0, 0.031),
  0px 1.1px 1.9px rgba(0, 0, 0, 0.044),
  0px 2.1px 3.6px rgba(0, 0, 0, 0.055),
  0px 3.8px 6.5px rgba(0, 0, 0, 0.066),
  0px 7.1px 12.1px rgba(0, 0, 0, 0.079),
  0px 17px 29px rgba(0, 0, 0, 0.11);
}

.about-us-page-about-each-member-section-row-gap {
  margin-top: 6vw;
}

/*About eacg of us text config*/
.about-me-responsive-text {
  font-size: calc(4px + 1vw) !important;
}

.about-us-name-title-text {
  position: absolute;
  height: 0;
  overflow: hidden;
  visibility: hidden;
  opacity: 0;
}


@media screen and (max-width: 992px) {
  .about-us-page-hero-section-big-text {
      font-size: calc(20px + 4vw);
  }

  .about-us-name-title-text-lg {
    position: absolute;
    width: 0;
    height: 0;
    overflow: hidden;
    visibility: hidden;
    opacity: 0;
  }
  
  .about-us-name-title-text {
    position: inherit;
    height: 100%;
    overflow: visible;
    visibility: visible;
    opacity: 1;
  }

  .about-me-responsive-text {
    font-size: calc(6px + 1vw) !important;
  }
}

@media screen and (max-width: 700px) {
  .about-us-page-hero-section-big-text {
      font-size: calc(20px + 5vw);
  }

  .about-me-responsive-text {
    font-size: calc(10px + 1vw) !important;
  }
}