.text-justify-spread-md {
    text-align: left;
}

.text-justify-spread-lg {
    text-align: left;
}

#model-container {
    background-image: linear-gradient(360deg, #fff, transparent 9%), url('../assets/img/sky.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.hero-section-phone-title-container {
    opacity: 0;
    position: absolute;
    visibility: hidden;
}

.hero-section-title {
    font-size: calc(20px + 10vw);
    margin-bottom: 0;
}

.hero-section-subtitle {
    font-size: calc(20px + 1vw);
    font-weight: lighter;
}

@media screen and (max-width: 990px) {
    .text-justify-spread-lg {
        text-align: justify !important;
        text-justify: inter-word !important;
    }
}

@media screen and (max-width: 699px) {
    .hero-section-phone-title-container {
        opacity: 1;
        position: relative;
        visibility: visible;
    }

    .hero-section-position-absolute-after-md {
        position: absolute;
        bottom: 90%;
        left: 50%;
        transform: translate(-50%, 100%);
    }

    .my-from-md-4{
        margin-top: 3em !important;
        margin-bottom: 3em !important;
    }
}

@media screen and (max-width: 570px) {
    .text-justify-spread-md {
        text-align: justify !important;
        text-justify: inter-word !important;
    }

    #about-us-section {
        min-height: 55vh !important;
        padding-top: 3em;
        padding-bottom: 3em;
    }

    #problems-we-solve-section {
        min-height: 55vh !important;
        padding-top: 3em;
        padding-bottom: 3em;
    }
}