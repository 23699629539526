.investor-relations-page-bg {
    background-image: url(../assets/img/investor-relations/investor-relations-bg-image.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.hero-images {
    height: 95%;
    width: 100%;
}

.our-product-image {
    box-shadow: 53px 50px 12px 0px rgba(0, 0, 0, 0.25);
}

/*Market Possibilities Section*/
.blurred-svg {
    width: 761px;
    height: 639px;
    clip-path: url(#figure-clipped);
    background: linear-gradient(#55555530, #21212140);
    backdrop-filter: blur(5px);
    position: absolute;
    z-index: -1;
}

.market-data-svg-container {
    position: absolute;
    right: 0;
    top: -20px;
}

#figure-object {
    box-shadow: 53px 50px 12px 0px rgba(0, 0, 0, 0.5);
}

@media screen and (max-width: 992px) {
    .market-data-svg-container {
        position: relative !important;
    }

    .blurred-svg {
        transform: scale(0.5);
        top: -50%;
        left: -25% !important;
    }

    #market-possibilities-svg {
        width: 380.5px;
        height: 319.5px;
    }

    .market-possibilities-container {
        min-height: 60vh !important;
    }
}

@media screen and (max-width: 778px) {
    .blurred-svg {
        transform: scale(0.5);
        top: -50%;
        left: -30% !important;
    }
}

@media screen and (max-width: 667px) {
    .blurred-svg {
        transform: scale(0.5);
        top: -50%;
        left: -36% !important;
    }
}

@media screen and (max-width: 550px) {
    .blurred-svg {
        transform: scale(0.5);
        top: -50%;
        left: -45% !important;
    }
}

@media screen and (max-width: 500px) {
    .hero-image {
        width: 35% !important;
        margin-left: 0.5em;
    }
    .hero-image:last-child {
        width: 30% !important;
    }
}

@media screen and (max-width: 467px) {
    .blurred-svg {
        transform: scale(0.4);
        top: -73%;
        left: -60% !important;
    }

    #market-possibilities-svg {
        width: 304.4px;
        height: 255.6px;
    }
}

@media screen and (max-width: 420px) {
    .blurred-svg {
        transform: scale(0.4);
        top: -73%;
        left: -66% !important;
    }
}

@media screen and (max-width: 380px) {
    .blurred-svg {
        transform: scale(0.4);
        top: -73%;
        left: -75% !important;
    }
}