.active-feature-section {
    background-color: #6FD1F0 !important;
    color: #212121 !important;
    border: 3px solid #6FD1F0 !important;
}

.feature-description {
    font-size: calc(3px + 0.7vw);
}

@media screen and (max-width: 767px) {
    .feature-description {
        font-size: calc(5px + 1vw);
    }
}

@media screen and (max-width: 570px) {
    .feature-description {
        font-size: calc(5px + 2vw);
    }
}