@import url('https://fonts.googleapis.com/css2?family=Anton&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Shanti&display=swap');

:root {
    --cyan: #6FD1F0;
    --dark: #212121;
}

textarea:focus, input:focus{
    outline: none;
}

canvas {
    display: block; /* fix necessary to remove space at bottom of canvas */
    position: fixed;
}

a {
    text-decoration: none;
}

.title-text {
    font-size: calc(20px + 3vw);
}

.responsive-text-1 {
    font-size: calc(3px + 1vw);
}

.responsive-text-1-2 {
    font-size: calc(3px + 1.2vw);
}

.responsive-text-2 {
    font-size: calc(10px + 1vw);
}

.responsive-text-4 {
    font-size: calc(10px + 2vw);
}

.text-color-dark {
    color: var(--dark);
}

.font-anton {
    font-family: 'Anton', sans-serif;
}

.font-inter {
    font-family: "Inter", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-variation-settings: "slnt" 0;
}

.font-inter-semibold {
    font-family: "Inter", sans-serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-style: normal;
    font-variation-settings: "slnt" 0;
}

.font-inter-light {
    font-family: "Inter", sans-serif;
    font-optical-sizing: auto;
    font-weight: 300;
    font-style: normal;
    font-variation-settings: "slnt" 0;
}

.font-shanti {
    font-family: "Shanti", sans-serif;
    font-weight: 400;
    font-style: normal;
  }

.text-spacing-3 {
    letter-spacing: 3px;
}

.cursor-pointer { 
    cursor: pointer;
}

.text-grey {
    color: #444444 !important;
}

.bg-blur {
    background-color: rgba(255, 255, 255, 0.3) !important;
    backdrop-filter: blur(5px) !important;
}

.bg-cyan {
    background-color: var(--cyan);
}

.bg-radial-cyan-dark {
    background: rgb(92,174,199);
    background: radial-gradient(circle, rgba(92,174,199,1) 0%, rgba(95,185,213,1) 18%, rgba(111,209,240,1) 58%);
}

.dark-text {
    color: var(--dark);
}

.border-rounded-10 {
    border-radius: 10px;
}

.move-from-header {
    padding-top: 10vh !important;
}

/*Scroll Styling*/
::-webkit-scrollbar {
    width: 5px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #444;
    border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #212121;
}

/*Menu Container*/
.menu-link-shadow {
    text-shadow: rgba(0, 0, 0, 0.1) 0px 2px 2px;
    transition: .3s ease-in-out;
}
.menu-link-shadow:hover {
    text-shadow: rgba(0, 0, 0, 0.25) 0px 7px 6.9px;
}

.flex-one {
    flex: 1 !important;
}

@media screen and (max-width: 991px) {
    .responsive-text-1 {
        font-size: calc(10px + 1vw);
    }
}

@media screen and (max-width: 767px) {
    .responsive-text-before-md-3 {
        font-size: calc(10px + 2vw) !important;
    }

    .min-h-before-md-auto {
        height: auto !important;
    }

    .mb-before-md-5 {
        margin-bottom: 3em;
    }

    .menu-bottom-svg {
        position: absolute;
        visibility: hidden;
        opacity: 0; 
    }

    .responsive-text-1-2 {
        font-size: calc(5px + 2vw);
    }

    .responsive-text-1 {
        font-size: calc(11px + 1vw);
    }
}