.box {
    --mask:
      radial-gradient(6.71vw at calc(100% - 9vw) 50%,#000 99%,#0000 101%) 0 calc(50% - 6vw)/100% 12vw,
      radial-gradient(6.71vw at calc(100% + 6vw) 50%,#0000 99%,#000 101%) calc(100% - 3vw) 50%/100% 12vw repeat-y;
    -webkit-mask: var(--mask);
            mask: var(--mask);
}

@media screen and (max-width: 991px) {
  .about-servin-hero-section-container {
    min-height: 100vh !important;
    height: auto !important;
  }

  .box {
    padding-top: 20%;
    --mask:
    radial-gradient(223.61px at 50% 300px,#000 99%,#0000 101%) calc(50% - 200px) 0/400px 100%,
    radial-gradient(223.61px at 50% -200px,#0000 99%,#000 101%) 50% 100px/400px 100% repeat-x;
  -webkit-mask: var(--mask);
          mask: var(--mask);
}
}

@media screen and (max-width: 700px) {
  .about-servin-hero-section-container {
    min-height: 50vh !important;
    height: auto !important;
  }

  .box {
    padding-top: 25%;
    --mask:
    radial-gradient(11.18vw at 50% 15vw,#000 99%,#0000 101%) calc(50% - 10vw) 0/20vw 100%,
    radial-gradient(11.18vw at 50% -10vw,#0000 99%,#000 101%) 50% 5vw/20vw 100% repeat-x;
  -webkit-mask: var(--mask);
          mask: var(--mask);
}
}
