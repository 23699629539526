.background-gradient {
    background: rgb(84,203,241);
    background: linear-gradient(169deg, rgba(84,203,241,1) 0%, rgba(111,209,240,1) 35%, rgba(111,209,240,1) 65%, rgba(84,203,241,1) 100%);
    background-size: 800% 800%;
    background-position: 0% 0%;
    animation: gradientMovement 4s infinite linear;    
}

.contacts-form {
    box-shadow:
        1.1px 1.4px 2.2px rgba(0, 0, 0, 0.011),
        2.5px 3.4px 5.3px rgba(0, 0, 0, 0.016),
        4.8px 6.4px 10px rgba(0, 0, 0, 0.02),
        8.5px 11.4px 17.9px rgba(0, 0, 0, 0.024),
        15.9px 21.3px 33.4px rgba(0, 0, 0, 0.029),
        38px 51px 80px rgba(0, 0, 0, 0.04)
    ;
}

.contacts-form-container {
    box-shadow: -30px 30px 4px 0px rgba(0, 0, 0, 0.25) ;
}

.contacts-form-title {
    position: absolute;
    top: -35%;
    left: 3%;
    background-color: #fff;
}

.button-shadow {
    box-shadow: 0px 4px 11.6px rgba(0, 0, 0, 0.33);
}

.choosen-form-section-button {
    background-color: #212121 !important;
    color: #6FD1F0 !important;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.33) !important;
    transition: .5s ease-in-out;
}

.submit-form-button {
    background-color: #fff;
    box-shadow: -2px 4px 4px 2px rgba(0, 0, 0, 0.25) ;
    transition: .5s ease-in-out;
    position: relative;
}
.submit-form-button:hover {
    color: #fff !important;
    text-shadow: 0px 0px 5px var(--cyan);
}

.submit-form-button::before {
    content: ' ';
    display: block;
    width: 0%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    background-color: #212121;
    transition: .4s ease-in-out;
}

.submit-form-button::after {
    content: ' ';
    display: block;
    width: 0%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    float: right;
    z-index: -1;
    background-color: #212121;
    transition: .4s ease-in-out;
}

.submit-form-button:hover::after, .submit-form-button:hover::before {
    width: 50%;
}

@media screen and (max-width: 767px) {
    #contacts-svg-container {
        position: relative !important;
    }

    .contacts-form-container {
        transform: translateY(-50px);
    }
}

@media screen and (max-width: 640px) {
    .contacts-options-section {
        background-color: #5ecef0;
        overflow-x: scroll;
        scrollbar-width: none !important;
        border-radius: 1em;
        height: 10vh;
        box-shadow: inset 0px 0px 10px 1px rgba(0, 0, 0, 0.35);
    }

    .contacts-options-container {
        width: 120%;
        padding-bottom: 0.9em !important;
        position: absolute;
        top: 50%;
        transform: translateY(-40%);
    }

    .contacts-option {
        padding-left: 0.4em !important;
        padding-right: 0.4em !important;
        margin-left: 0.5em;
    }

    #contacts-svg-container {
        position: relative !important;
    }

    .contacts-form-container {
        transform: translateY(-50px);
    }
}

@keyframes gradientMovement {
    0% {
        background-position: 0% 0%;
    } 99.9% {
        background-position: 100% 100%;
    } 100% {
        background-position: 0% 0%;
    }
}